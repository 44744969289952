module.exports = {
    pwa: {
        workboxPluginMode: "InjectManifest",
        workboxOptions: {
            swSrc: "src/service-worker.js",
        }
    },
    devServer: {
        proxy: 'https://uat.bippotalk.com'
        // proxy: 'http://localhost:3012'
    },
    // chainWebpack: (config) => {
    //     config.plugins.delete('preload')
    // }
}