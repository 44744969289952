<template>
    <div v-if="show">
        <div v-if="selectedFilter == 'month'">
            <div class="row">
                <div class="col">
                    <DashboardBar
                        :chartId="sumDataChatMonthOp.id"
                        :chartData="sumDataChatMonthOp.data"
                        :datasetIdKey="sumDataChatMonthOp.datasetIdKey"
                        :width="chart.width"
                        :height="chart.height"
                        :cssClasses="chart.cssClasses"
                        :styles="chart.styles"
                        :plugins="chart.plugins"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h2>Chat Operator Per Periode</h2>
                    <v-data-table
                        height="600"
                        :headers="headersOp"
                        :items="dataChatMonthOp"
                        fixed-header
                        hide-default-footer
                        disable-pagination
                        class="elevation-1"
                    >
                    </v-data-table>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h2>Chat Operator Per Waktu</h2>
                    <v-data-table
                        height="600"
                        :headers="headers"
                        :items="dataChatMonth"
                        fixed-header
                        hide-default-footer
                        disable-pagination
                        class="elevation-1"
                    >
                    </v-data-table>
                </div>
            </div>
        </div>
        <div class="row" v-if="selectedFilter == 'day'">
            <div class="col-md-6 text-center">
                <h3>Pesan Dalam Penanganan</h3>
                <DashboardDoughnut 
                    :chartId="chatOn.id"
                    :chartData="chatOn.data"
                    :datasetIdKey="chatOn.datasetIdKey"
                    :width="chart.width"
                    :height="chart.height"
                    :cssClasses="chart.cssClasses"
                    :styles="chart.styles"
                    :plugins="chart.plugins"
                />
            </div>
            <div class="col-md-6 text-center">
                <h3>Pesan Ditutup</h3>
                <DashboardDoughnut 
                    :chartId="chatClose.id"
                    :chartData="chatClose.data"
                    :datasetIdKey="chatClose.datasetIdKey"
                    :width="chart.width"
                    :height="chart.height"
                    :cssClasses="chart.cssClasses"
                    :styles="chart.styles"
                    :plugins="chart.plugins"
                />
            </div>
            <div class="col-md-6 text-center p-5">
                <h3>Proporsi Pesan</h3>
                <DashboardPie 
                    :chartId="chatIn.id"
                    :chartData="chatIn.data"
                    :datasetIdKey="chatIn.datasetIdKey"
                    :width="chart.width"
                    :height="chart.height"
                    :cssClasses="chart.cssClasses"
                    :styles="chart.styles"
                    :plugins="chart.plugins"
                />
            </div>
            <div class="col-md-12 text-center p-5">
                <h3>Pesan Dalam Penanganan Tanggal {{ startDate }}</h3>
                <DashboardLine 
                    :chartId="sumChatDay.id"
                    :chartData="sumChatDay.data"
                    :datasetIdKey="sumChatDay.datasetIdKey"
                    :width="chart.width"
                    :height="chart.height"
                    :cssClasses="chart.cssClasses"
                    :styles="chart.styles"
                    :plugins="chart.plugins"
                />
            </div>

            <div class="col-md-12 text-center p-5">
                <h3>Pesan Dalam Penanganan Tanggal {{ startDate }} Per Operator</h3>
                <DashboardLine 
                    :chartId="sumChatDayOp.id"
                    :chartData="sumChatDayOp.data"
                    :datasetIdKey="sumChatDayOp.datasetIdKey"
                    :width="chart.width"
                    :height="600"
                    :cssClasses="chart.cssClasses"
                    :styles="chart.styles"
                    :plugins="chart.plugins"
                />
            </div>

        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { mapState } from 'vuex'

    import DashboardBar from "./DashboardBar.vue"
    import DashboardPie from "./DashboardPie.vue"
    import DashboardDoughnut from "./DashboardDoughnut.vue"
    import DashboardLine from "./DashboardLine.vue"

    var moment = require('moment');

    export default {
        props: ['show','selectedStartDate', 'selectedFilter', 'startDate', 'selectedMonth'],

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },

        data: function () {
            return {
                dataChatMonth: Array(),
                dataChatMonthOp: Array(),

                chart: {
                    width: 400,
                    height: 400,
                    cssClasses: String(),
                    styles: Object(),
                    plugins: Array(),
                },

                sumDataChatMonthOp: {
                    id: 'sum_chat_month_op',
                    datasetIdKey: 'sum_chat_month_op',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: "#198754",
                            label : "",
                            data: []
                        }]
                    },
                },

                chatIn: {
                    id: 'chat_in',
                    datasetIdKey: 'chat_in',
                    data: {
                        labels: ['Belum Ditangani', 'Dalam Penanganan', 'Ditutup'],
                        datasets: [{
                            backgroundColor: ['#198754', '#0dcaf0', '#dc3545'],
                            data: []
                        }]
                    },
                },

                chatOn: {
                    id: 'chat_on',
                    datasetIdKey: 'chat_on',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: [],
                            data: []
                        }]
                    },
                },

                chatClose: {
                    id: 'chat_close',
                    datasetIdKey: 'chat_close',
                    data: {
                        labels: [],
                        datasets: [{
                            backgroundColor: [],
                            data: []
                        }]
                    },
                },

                sumChatDay: {
                    id: 'sum_chat_day',
                    datasetIdKey: 'sum_chat_day',
                    data: {
                        labels: [],
                        datasets: [{
                            label: 'Total Chat',
                            backgroundColor: ['#198754'],
                            data: []
                        }]
                    },
                },

                sumChatDayOp: {
                    id: 'sum_chat_day_op',
                    datasetIdKey: 'sum_chat_day_op',
                    data: {
                        labels: [],
                        datasets: [{
                            label: 'Total Chat',
                            backgroundColor: ['#198754'],
                            data: []
                        }]
                    },
                },

                headersOp: [
                    { text: '', value: 'text', sortable: false, width: '105px' },
                ],

                headers: [
                    { text: '', value: 'text', sortable: false, width: '105px' },
                    { text: '0:00', value: 'J0.count', sortable: false },
                    { text: '1:00', value: 'J1.count', sortable: false },
                    { text: '2:00', value: 'J2.count', sortable: false },
                    { text: '3:00', value: 'J3.count', sortable: false },
                    { text: '4:00', value: 'J4.count', sortable: false },
                    { text: '5:00', value: 'J5.count', sortable: false },
                    { text: '6:00', value: 'J6.count', sortable: false },
                    { text: '7:00', value: 'J7.count', sortable: false },
                    { text: '8:00', value: 'J8.count', sortable: false },
                    { text: '9:00', value: 'J9.count', sortable: false },
                    { text: '10:00', value: 'J10.count', sortable: false },
                    { text: '11:00', value: 'J11.count', sortable: false },
                    { text: '12:00', value: 'J12.count', sortable: false },
                    { text: '13:00', value: 'J13.count', sortable: false },
                    { text: '14:00', value: 'J14.count', sortable: false },
                    { text: '15:00', value: 'J15.count', sortable: false },
                    { text: '16:00', value: 'J16.count', sortable: false },
                    { text: '17:00', value: 'J17.count', sortable: false },
                    { text: '18:00', value: 'J18.count', sortable: false },
                    { text: '19:00', value: 'J19.count', sortable: false },
                    { text: '20:00', value: 'J20.count', sortable: false },
                    { text: '21:00', value: 'J21.count', sortable: false },
                    { text: '22:00', value: 'J22.count', sortable: false },
                    { text: '23:00', value: 'J23.count', sortable: false },
                ],

            }
        },


        watch: {

        },

        mounted() {

        },

        methods: {
            loadDataTypeChat: async function() {
                const params = Object()
                params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat', {
                        params
                    })

                    this.chatIn.data.datasets[0].data = [data.countDraft, data.countActive, data.countInactive]

                } catch (error) {
                    console.log("error search sales order", error);
                }
            },

            loadDataChatActive: async function() {
                const params = Object()
                params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/active', {
                        params
                    })

                    let tempLabel = Array()
                    let tempDataSet = Array()
                    let tempColors = Array()

                    data.forEach(e => {
                        tempLabel.push(e._id)
                        tempDataSet.push(e.count)
                        tempColors.push(this.setOperatorColor(e._id))
                    })

                    this.chatOn.data.labels = tempLabel
                    this.chatOn.data.datasets[0].data = tempDataSet
                    this.chatOn.data.datasets[0].backgroundColor = tempColors
                } catch (error) {
                    console.log("error search sales order", error);
                }
            },

            loadDataChatClose: async function() {const params = Object()
                params.startDate = this.selectedStartDate ? moment(this.selectedStartDate).format() : ""
                params.endDate = this.selectedStartDate ? moment(this.selectedStartDate).endOf('day').format() : ""

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/close', {
                        params
                    })

                    let tempLabel = Array()
                    let tempDataSet = Array()
                    let tempColors = Array()

                    data.forEach(e => {
                        tempLabel.push(e._id)
                        tempDataSet.push(e.count)
                        tempColors.push(this.setOperatorColor(e._id))
                    })

                    this.chatClose.data.labels = tempLabel
                    this.chatClose.data.datasets[0].data = tempDataSet
                    this.chatClose.data.datasets[0].backgroundColor = tempColors
                } catch (error) {
                    console.log("error search sales order", error);
                }
            },

            getSumChatPerDay: async function(isToday, hour, day) {
                try {
                    const sumChatDay = { ...this.sumChatDay }

                    sumChatDay.data =  {
                        labels: [],
                        datasets: [{
                            label: 'Total Chat',
                            backgroundColor: ['#198754'],
                            data: []
                        }]
                    }

                    const params = Object()
                    params.startDate = moment(this.selectedStartDate).format()
                    params.endDate = moment(this.selectedStartDate).endOf('day').format()
                    params.day = day

                    if(isToday) params.hour = hour

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/day', {
                        params
                    })

                    data.forEach(e => {
                        sumChatDay.data.labels.push(e.text)
                        sumChatDay.data.datasets[0].data.push(e.totalChat)
                    })

                    this.sumChatDay = sumChatDay
                } catch (error) {
                    console.log('get-chat-per-day', error);
                }
            },

            getSumChatPerDayOp: async function(isToday, hour, day) {
                try {
                    const params = Object()
                    params.startDate = moment(this.selectedStartDate).format()
                    params.endDate = moment(this.selectedStartDate).endOf('day').format()
                    params.day = day

                    if(isToday) params.hour = hour

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/day/op', {
                        params
                    })

                    const datas = {
                        labels: [],
                        datasets: []
                    }

                    data.forEach((e, i) => {
                        const color = this.setOperatorColor(e.operatorId)

                        if(i == 0 ) datas.labels = e.chatDay.map(map => map.text)
                        datas.datasets.push({
                            label: e.operatorId,
                            backgroundColor: color,
                            data: e.chatDay.map(map => map.totalChat)
                        })
                    })

                    this.sumChatDayOp = {
                        ...this.sumChatDayOp,
                        data: datas
                    }

                } catch (error) {
                    console.log('get-chat-per-day', error);
                }
            },

            getChatPerMonth: async function(isMonth, dateNow) {
                try {
                    var selectedMonth = this.selectedMonth.split('-');

                    const params = Object()
                    params.year = selectedMonth[0]
                    params.month = selectedMonth[1]

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/month', {
                        params
                    })

                    const endOfMonth = moment([selectedMonth[0], selectedMonth[1] - 1]).endOf('month').date();

                    this.dataChatMonth = this.chatPerMonth(data, endOfMonth, isMonth, dateNow)

                } catch (error) {
                    console.log('get-chat-per-day', error);
                }
            },

            getChatPerMonthOp: async function(isMonth, dateNow) {
                try {
                    var selectedMonth = this.selectedMonth.split('-');

                    const params = Object()
                    params.year = selectedMonth[0]
                    params.month = selectedMonth[1]

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/chat/month/op', {
                        params
                    })

                    const endOfMonth = moment([selectedMonth[0], selectedMonth[1] - 1]).endOf('month').date();

                    const chatPerMonthOp = this.chatPerMonthOp(data, selectedMonth)
                    this.sumDataChatMonthOp.data.labels = chatPerMonthOp.labels
                    this.sumDataChatMonthOp.data.datasets[0].label = chatPerMonthOp.label
                    this.sumDataChatMonthOp.data.datasets[0].data = chatPerMonthOp.data

                    this.dataChatMonthOp = this.sumChatPerMonthOp(data, endOfMonth, isMonth, dateNow)

                } catch (error) {
                    console.log('get-chat-per-month', error);
                }
            },

            chatPerMonth: function(data, endOfMonth, isMonth, dateNow) {
                const temp = []

                let day = endOfMonth

                if(isMonth) day = dateNow

                for (let i = 1; i <= day; i++) {
                    var obj = {
                        id: i,
                        text: this.selectedMonth + '-' + i
                    }
                    
                    for (let j = 0; j < 24; j++) {
                        obj["J" + j] = {
                            id: j,
                            count: 0
                        }
                    }

                    temp.push(obj)
                }

                data.forEach(e => {
                    e.hours.forEach(el => {
                        if(el) temp[e._id - 1]["J" + el._id].count = el.totalInHour
                    })
                })

                return temp
            },

            chatPerMonthOp: function(data, selectedMonth) {
                const temp = Array()

                data.forEach(el => {
                    el.hours.forEach(e => {
                        if(temp.length < 0) {
                            temp.push({
                                operatorId: e.operatorId,
                                count: e.totalInHour
                            })
                        } else {
                            const index = temp.findIndex(op => op.operatorId == e.operatorId)
                            if(index >= 0) {
                                temp[index].count = temp[index].count + e.totalInHour
                            } else {
                                temp.push({
                                    operatorId: e.operatorId,
                                    count: e.totalInHour
                                })
                            }
                        }
                    })
                })

                let tempLabel = Array()
                let tempDataSet = Array()

                temp.forEach(e => {
                    tempLabel.push(e.operatorId ? e.operatorId : String())
                    tempDataSet.push(e.count)
                })

                return {
                    labels: tempLabel,
                    label: "Jumlah Chat Bulan " + this.convertMonth(parseInt(selectedMonth[1]) - 1),
                    data: tempDataSet
                }
            },

            sumChatPerMonthOp: function(data, endOfMonth, isMonth, dateNow) {
                const temp = []
                const listOperator = []

                data.forEach(e => {
                    e.hours.forEach(el => {
                        if(listOperator.length < 0) {
                            listOperator.push({
                                text: el.operatorId, 
                                value: el.operatorId + '.' + 'total', 
                                sortable: false 
                            })
                        } else {
                            const index = listOperator.findIndex(op => op.text == el.operatorId)

                            if(index == -1) {
                                listOperator.push({
                                    text: el.operatorId, 
                                    value: el.operatorId + '.' + 'total', 
                                    sortable: false 
                                })
                            }
                        }
                    })
                })

                this.headersOp = [
                    ...this.headersOp,
                    ...listOperator
                ]

                let day = endOfMonth

                if(isMonth) day = dateNow

                for (let i = 1; i <= day; i++) {
                    var obj = {
                        id: i,
                        text: this.selectedMonth + '-' + i
                    }

                    listOperator.forEach(e => {
                        obj[e.text] = {
                            id: e.text,
                            total: 0
                        }
                    })

                    temp.push(obj)
                }

                data.forEach(e => {
                    e.hours.forEach(el => {
                        if(el) temp[e._id - 1][el.operatorId].total = temp[e._id - 1][el.operatorId].total + el.totalInHour
                    })
                })

                return temp
            },

            convertMonth: function(month) {
                const listMonth = [
                    'Januari',
                    'Februari',
                    'Maret',
                    'April',
                    'Mei',
                    'Juni',
                    'July',
                    'Agustus',
                    'September',
                    'Oktober',
                    'November',
                    'Desember'
                ]

                return listMonth[month]
            },

            setOperatorColor: function(id) {
                // '#198754' hijau, 
                // '#0dcaf0' biru,
                // '#dc3545' merah,
                // '#ced4da' abu,

                // '#0d6efd' biru tua (Add)
                // '#6732d7' ungu (Add)
                // '#eda247' orange (Add)
                // '#fc1fa9' pink (Add)
                // '#654321' coklat (Add)
                // '#003366' navy (Add)
                // '#065535' hijau tua
                // '#ccff00' stabilo
                // '#66cccc' telor asin
                
                if(id == 'resti_nurjamilah') {
                    // pink
                    return '#fc1fa9'
                } else if(id == 'sahara2') {
                    // ungu
                    return '#6732d7'
                } else if(id == 'desni_aghni_amalinisa') {
                    // biru tua
                    return '#0d6efd'
                } else if(id == 'ulfiah_alamatus_sa_adah') {
                    // navy
                    return '#003366'
                } else if(id == 'selindri_nurifatikah3') {
                    // orange
                    return '#eda247'
                } else if(id == 'redi') {
                    // coklat
                    return '#654321'
                } else if(id == 'ingga_bia_putri') {
                    // stabilo
                    return '#ccff00'
                } else if(id == 'hervin') {
                    // telor asin
                    return '#66cccc'
                } else {
                    // hijau
                    return '#198754'
                }
            },
        },

        components: {
            DashboardBar,
            DashboardPie,
            DashboardDoughnut,
            DashboardLine
        },

    }

</script>
