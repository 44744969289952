var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-gauge',{class:'meticsGauge',attrs:{"refid":_vm.refid,"options":{
            needleValue: _vm.needleValue,
            centralLabel: _vm.centralLabel,
            needleColor: _vm.needleColor,
            arcDelimiters: _vm.arcDelimiters,
            arcColors: _vm.arcColors,
            chartWidth: _vm.chartWidth,
            hasNeedle: _vm.hasNeedle,
            rangeLabel: _vm.rangeLabel,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }