<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Count Sales & Return
                                            <!-- <router-link :to="{ name: 'broadcast' , query: { tabs: 'add' } }" v-if="showPage == 'list'">
                                                <v-btn class="btn btn-primary pull-right text-white btn btn-primary">
                                                    Buat Broadcast
                                                </v-btn>
                                            </router-link> -->
                                            <div class="clearfix"></div>
                                            <br>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <template>
                                                    <div class="list-auto-template">
                                                        <v-row>
                                                            <v-col cols="2" class="pt-7">
                                                                <v-menu ref="startDate" v-model="isOpenStartDate" :close-on-content-click="false" :nudge-right="40"
                                                                    :return-value.sync="selectedStartDate" transition="scale-transition" min-width="290px" offset-y>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-text-field :value="selectedStartDate ? utcToLocal(selectedStartDate,'DD-MM-YYYY') : ''"
                                                                        class="" label="Pilih Tanggal" readonly dense hide-details v-on="on" />
                                                                    </template>
                                                                    <v-date-picker v-model="startDate" no-title scrollable @input="$refs.startDate.save(startDate)" />
                                                                </v-menu>
                                                            </v-col>
                                                            <!-- <v-col cols="2" class="pt-7">
                                                                <v-menu ref="endDate" v-model="isOpenEndDate" :close-on-content-click="false" :nudge-right="40"
                                                                :return-value.sync="selectedEndDate" transition="scale-transition" min-width="290px" offset-y>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field readonly dense hide-details
                                                                    :value="selectedEndDate ? utcToLocal(selectedEndDate,'DD-MM-YYYY') : ''" class=""
                                                                    label="Tanggal Selesai" v-on="on" />
                                                                </template>
                                                                <v-date-picker v-model="endDate" no-title scrollable :min="selectedStartDate ? selectedStartDate : ''"
                                                                    @input="$refs.endDate.save(endDate)" />
                                                                </v-menu>
                                                            </v-col> -->
                                                            <!-- <v-col cols="3">
                                                                <v-select v-model="selectedStatus" :items="status" label="Status"></v-select>
                                                            </v-col> -->
                                                            <v-col cols="3" class="pt-7">
                                                                <v-btn @click="loadSessionInfo" class="mr-2 text-white btn btn-primary">
                                                                    Cari
                                                                </v-btn>
                                                                <v-btn @click="resetFilter">
                                                                    Reset
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
    
                                                        <div class="my-5">
                                                            <v-data-table class="elevation-1"
                                                                :headers="headers" 
                                                                :items="sessionList" 
                                                                :loading="isLoading"
                                                                :hide-default-footer="true"
                                                            >
                                                                <template v-slot:item.handledMessageTime="{ item }">
                                                                    <div >
                                                                        {{ item.onTime }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.order="{ item }">
                                                                    <div class="text-center" v-if="item.salesOrders.length > 0">
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <a
                                                                                    color="primary"
                                                                                    dark
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                >
                                                                                    {{ item.salesOrders.length }}
                                                                                    No Penjualan <i class="mdi mdi-chevron-down"></i>
                                                                                </a>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item v-for="order in item.salesOrders" :key="order.formalId">
                                                                                    <v-list-item-title>{{ order.formalId }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:item.retur="{ item }">
                                                                    <div class="text-center" v-if="item.returnOrders.length > 0">
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <a
                                                                                    color="primary"
                                                                                    dark
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                >
                                                                                    {{ item.returnOrders.length }} No Pengembalian
                                                                                </a>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item v-for="retur in item.returnOrders" :key="retur.formalId">
                                                                                    <v-list-item-title>{{ retur.formalId }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </div>
                                                                </template>
                                                            </v-data-table>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'

    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');


    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
                operatorList: state => state.operator.operatorList,
            })
        },
        data: function () {
            return {
                formValid: false,
                isOpenStartDate: false,
                selectedStartDate: String(),
                startDate: String(),
                // isOpenEndDate: false,
                // selectedEndDate: String(),
                // endDate: String(),
                headers: [
                    { text: '', value: '_id', sortable: false },
                    { text: 'Penjualan Pelanggan', value: 'countOrderNonPartner', sortable: false },
                    { text: 'Penjualan Partner', value: 'countOrderPartner', sortable: false },
                    { text: 'Pengembalian Pelanggan', value: 'countReturnNonPartner', sortable: false },
                    { text: 'Pengembalian Partner', value: 'countReturnPartner', sortable: false }
                ],
                sessionList: Array(),
                options: Object(),
                isLoading: false,
                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },

        watch: {

        },

        methods: {
            resetFilter: async function() {
                this.isOpenStartDate = false,
                this.selectedStartDate = String()
                this.startDate = String(),
                // this.isOpenEndDate = false,
                // this.selectedEndDate = String()
                // this.endDate = String(),

                await this.loadSessionInfo()
            },
            loadSessionInfo: async function() {
                this.isLoading = true

                let params = Object();

                params.startDate = this.startDate ? moment(this.startDate).format() : String()
                params.endDate = this.startDate ? moment(this.startDate).endOf('day').format() : String()

                try {

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/sales-return/count', {
                        params
                    })
                    
                    this.sessionList = data.map(e => {
                        e.countOrderNonPartner = 0
                        e.countOrderPartner = 0
                        e.countReturnNonPartner = 0
                        e.countReturnPartner = 0

                        if(e.orders) {
                            e.orders.customerRoles.forEach(el => {
                                if(el._id == 'common') e.countOrderNonPartner += el.total
                                if(el._id != 'common') e.countOrderPartner += el.total
                            })
                        }

                        if(e.returns) {
                            e.returns.customerRoles.forEach(el => {
                                if(el._id == 'common') e.countReturnNonPartner += el.total
                                if(el._id != 'common') e.countReturnPartner += el.total
                            })
                        }

                        return e
                    })
                } catch (error) {
                    console.log("load-broadcast", error);
                }
                this.isLoading = false
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();

            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif,
        }
    }
</script>